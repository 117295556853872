<template>
  <el-container class="shopList">
    <el-header class="box add bb df">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>商品发货管理</el-breadcrumb-item>
        <el-breadcrumb-item>发货列表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button size="medium" type="primary" @click="add">新增发货</el-button>
    </el-header>
    <el-main class="p15 pt0">
      <el-form :inline="true" :model="page" class="demo-form-inline">
        <div class="df aic" style="margin-top: 20px">
          <el-form-item size="medium" style="flex: 0 0 15%" class="mr10">
            <el-input
              placeholder="发货订单号"
              v-model="page.shipping_sn"
              clearable
              class="input-with-select"
              @clear="onSubmit(page.shipping_sn)"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="medium" style="flex: 0 0 15%" class="mr10">
            <el-input
              placeholder="收货公司"
              v-model="page.consignee_company_name"
              clearable
              class="input-with-select"
              @clear="onSubmit(page.shipping_sn)"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="medium" style="flex: 0 0 15%" class="mr10">
            <el-input
              placeholder="商品名称"
              v-model="page.goods_name"
              clearable
              class="input-with-select"
              @clear="onSubmit(page.goods_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="medium" style="flex: 0 0 15%" class="mr10">
            <el-input
              placeholder="商品品码"
              v-model="page.gtin"
              clearable
              class="input-with-select"
              @clear="onSubmit(page.shipping_sn)"
            >
            </el-input>
          </el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
            style="height: 34px; margin-bottom: 20px; border: none"
            class="mr10"
            >查询</el-button
          >
          <el-upload
            class="upload"
            action="#"
            :multiple="false"
            ref="upload"
            accept=".xlsx"
            :limit="1"
            :show-file-list="false"
            :before-upload="beforeUpload"
            style="float: right"
          >
            <el-button
              type="success"
              size="medium"
              style="height: 34px; margin-bottom: 20px; border: none"
              class="mr10"
              @click="batchAdd"
              >批量导入</el-button
            >
          </el-upload>
          <el-button
            size="medium"
            type="warning"
            style="height: 34px; margin-bottom: 20px; border: none"
            class="mr10"
            @click="downloadInfo(1)"
            >下载模板</el-button
          >
        </div>
        <div class="df aic">
          <el-form-item style="margin-right: 0">
            <el-date-picker
              type="date"
              placeholder="开始日期"
              v-model="add_time"
              :picker-options="pickerOptionsStart"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="change(1, add_time)"
            ></el-date-picker>
          </el-form-item>
          <div class="df aic jcc" style="flex: 0 0 40px; margin-bottom: 20px">
            至
          </div>
          <el-form-item class="mr10">
            <el-date-picker
              type="date"
              placeholder="结束日期"
              v-model="end_time"
              :picker-options="pickerOptionsEnd"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="change(2, end_time)"
            ></el-date-picker>
          </el-form-item>
          <el-button
            size="medium"
            type="warning"
            @click="exportData(1)"
            style="height: 38px; margin-bottom: 20px"
            class="mr10"
            >导出数据</el-button
          >
          <div style="margin-bottom: 20px; margin-left: 50px">
            <div style="color: #909399">
              货物总价:
              <span style="color: #0081ff; font-weight: bold">{{
                totalPrices
              }}</span>
              元
            </div>
          </div>
        </div>
      </el-form>
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column min-width="120" type="expand">
            <template slot-scope="props">
              <el-form
                label-position="left"
                inline
                class="demo-table-expand pl30 pr30"
              >
                <el-form-item label="商品重量">
                  <h5>
                    {{
                      props.row.goods_weight
                        ? props.row.goods_weight + "kg"
                        : "未填写"
                    }}
                  </h5>
                </el-form-item>
                <el-form-item label="单个重量">
                  <h5>
                    {{
                      props.row.goods_unit
                        ? props.row.goods_unit + "kg"
                        : "未填写"
                    }}
                  </h5>
                </el-form-item>
                <el-form-item label="货品总数">
                  <h5>
                    {{
                      props.row.goods_sum
                        ? props.row.goods_sum + "件"
                        : "未填写"
                    }}
                  </h5>
                </el-form-item>
                <el-form-item label="货品件数">
                  <h5>
                    {{
                      props.row.goods_number
                        ? props.row.goods_number + "件"
                        : "未填写"
                    }}
                  </h5>
                </el-form-item>
                <el-form-item label="货品总价">
                  <h5>
                    {{
                      props.row.goods_total_price
                        ? props.row.goods_total_price + "元"
                        : "未填写"
                    }}
                  </h5>
                </el-form-item>
                <div>
                  <el-form-item label="运输车辆">
                    <h5>
                      {{
                        props.row.car_number ? props.row.car_number : "未填写"
                      }}
                    </h5>
                  </el-form-item>
                  <el-form-item label="发货人姓名">
                    <h5>{{ props.row.created_name }}</h5>
                  </el-form-item>
                </div>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
            prop="shipping_sn"
            min-width="120"
            :show-overflow-tooltip="true"
            label="发货订单号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="goods_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            min-width="120"
            :show-overflow-tooltip="true"
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'finished'" style="color: green"
                >已完成</span
              >
              <span v-if="scope.row.status === 'shipped'" style="color: orange"
                >已发货</span
              >
              <span v-if="scope.row.status === 'pending'" style="color: blue"
                >待发货</span
              >
              <span v-if="scope.row.status === 'invalid'" style="color: red"
                >已作废</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ship_time"
            min-width="100"
            :show-overflow-tooltip="true"
            label="发货时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="batch_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="批次号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="consignee_company_name"
            min-width="160"
            :show-overflow-tooltip="true"
            label="收货公司"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="goods_total_price"
            min-width="160"
            :show-overflow-tooltip="true"
            label="总价(元)"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="unitWeight"
            min-width="160"
            :show-overflow-tooltip="true"
            label="数量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="qualification"
            min-width="160"
            :show-overflow-tooltip="true"
            label="相关资证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="showImage(scope.row)"
                class="general_bgc general_border cfff"
                >查看图片</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="real_pics"
            min-width="160"
            :show-overflow-tooltip="true"
            label="发货实景"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="showImage1(scope.row)"
                class="general_bgc general_border cfff"
                >查看图片</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="car_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="运输车辆"
            align="center"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="created_name"
            min-width="160"
            :show-overflow-tooltip="true"
            label="发货人姓名"
            align="center"
          >
          </el-table-column> -->
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="showViewer">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-dialog :visible.sync="showResult" width="60%" title="导入确认">
        <div style="height: 800px; flex-direction: column" class="df aic">
          <div
            style="flex: 1; height: 0; overflow-y: scroll; width: 100%"
            class="result-box"
          >
            <div style="">
              <div class="df aic">
                <h2 style="margin: 0px 0 10px">
                  该Excel中与该企业不存在供销关系的企业有<span
                    style="color: red"
                    >{{ supplyNotExist.length }}</span
                  >条
                </h2>
              </div>
              <el-table :data="supplyNotExist" border>
                <el-table-column
                  prop="goods_name"
                  min-width="120"
                  :show-overflow-tooltip="true"
                  label="商品名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="gtin"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="品码"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="batch_number"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="批次号"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="consignee_company_name"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="收货企业"
                  align="center"
                >
                </el-table-column>
              </el-table>
              <div class="df aic">
                <h2 style="margin: 20px 0px 10px">
                  该Excel中商品不存在的信息有<span style="color: darkblue">{{
                    goodsExist.length
                  }}</span
                  >条
                </h2>
              </div>
              <el-table :data="goodsExist" border>
                <el-table-column
                  prop="goods_name"
                  min-width="120"
                  :show-overflow-tooltip="true"
                  label="商品名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="gtin"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="品码"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="batch_number"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="批次号"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="consignee_company_name"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="收货企业"
                  align="center"
                >
                </el-table-column>
              </el-table>
              <div class="df aic">
                <h2 style="margin: 20px 0 10px">
                  该Excel中商品可导入信息有<span style="color: darkblue">{{
                    infoUpload.length
                  }}</span
                  >条
                </h2>
              </div>
              <el-table
                border
                :data="infoUpload"
                @select="handleChecked"
                @select-all="handleCheckedAll"
                ref="infoTable"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                  prop="goods_name"
                  min-width="120"
                  :show-overflow-tooltip="true"
                  label="商品名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="gtin"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="品码"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="batch_number"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="批次号"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="consignee_company_name"
                  min-width="80"
                  :show-overflow-tooltip="true"
                  label="收货企业"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="df aic jcc" style="height: 60px; width: 100%">
            <el-button
              style="height: 34px; border: none; margin-top: 1px"
              class="mr10"
              type="primary"
              size="medium"
              @click="confirmInfo"
              >确认导入({{
                infoUpload.filter((v) => v.checked).length
              }}条)</el-button
            >
            <el-button
              style="height: 34px; border: none; margin-top: 1px"
              class="mr10"
              type="info"
              size="medium"
              @click="showResult = false"
              >&emsp;取消&emsp;</el-button
            >
          </div>
        </div>
      </el-dialog>
      <!-- <el-dialog :visible.sync="showResult" height="500px">
        <h2>
          该Excel中与该企业不存在供销关系的企业有<span style="color: red">{{
            supplyNotExist.length
          }}</span
          >条
        </h2>
        <el-table :data="supplyNotExist">
          <el-table-column
            prop="goods_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="batch_number"
            min-width="80"
            :show-overflow-tooltip="true"
            label="批次号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="consignee_company_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="收货企业"
            align="center"
          >
          </el-table-column>
        </el-table>
        <h2>
          该Excel中商品不存在的信息有<span style="color: darkblue">{{
            goodsExist.length
          }}</span
          >条
        </h2>
        <el-table :data="goodsExist">
          <el-table-column
            prop="goods_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="batch_number"
            min-width="80"
            :show-overflow-tooltip="true"
            label="批次号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="consignee_company_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="收货企业"
            align="center"
          >
          </el-table-column>
        </el-table>
         <h2>
          该Excel中商品可导入信息有<span style="color: darkblue">{{
            infoUpload.length
          }}</span
          >条
        </h2>
        <el-table :data="infoUpload" @select="handleChecked" @select-all="handleCheckedAll">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="goods_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="batch_number"
            min-width="80"
            :show-overflow-tooltip="true"
            label="批次号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="consignee_company_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="收货企业"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-dialog> -->

      <el-dialog title="相关资证" :visible.sync="picDialogVisible" top="8vh">
        <div v-if="reportList.length > 0">
          <el-divider content-position="left">
            <el-tag>资证报告</el-tag>
          </el-divider>
          <el-row :gutter="10">
            <el-col :span="6" v-for="(item, index) in reportList" :key="index">
              <el-image
                style="height: 200px"
                :src="item"
                :preview-src-list="reportList"
                z-index="99999"
              ></el-image>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <span>暂无</span>
        </div>
      </el-dialog>
      <el-dialog title="发货实景" :visible.sync="picDialogVisible1" top="8vh">
        <div v-if="attachList.length > 0">
          <el-divider content-position="left">
            <el-tag type="success">发货实景</el-tag>
          </el-divider>
          <el-row :gutter="10">
            <el-col :span="6" v-for="(item, index) in attachList" :key="index">
              <el-image
                style="height: 200px"
                :src="item"
                :preview-src-list="attachList"
              ></el-image>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <span>暂无</span>
        </div>
      </el-dialog>
    </el-main>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local } from "@/util/util";
import { mapState } from "vuex";
// import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import api from "@/util/extra-api";

export default {
  components: {},
  data() {
    return {
      files: "",
      user: {},
      page: {
        supply_name: "", //供销单位的名称
        concat: "", //联系人
        pager_offset: "0",
        pager_openset: "10",
      },
      picDialogVisible: false,
      picDialogVisible1: false,
      reportList: [],
      attachList: [],
      add_time: "",
      end_time: "",
      companyName: "",
      timer: "",
      loading: false,
      currentPage: 1,
      total: 0,
      tableData: [],
      goodsExist: [],
      infoUpload: [],
      supplyNotExist: [],
      batchError: [],
      activeName: "1",
      company_id: "",
      url: "",
      uploadVis: false,
      showViewer: false,
      showResult: false,
      dialogImageUrl: "",
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.page.end_time) {
            return time.getTime() > new Date(this.page.end_time).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      value: [],
      totalPrices: 0,
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.page);
    this.user = local.get("user");
    this.page.company_id = String(local.get("company_id"));
    this.companyName = String(local.get("company_name"));
    this.page.user_id = String(this.user.user_id);
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    confirmInfo() {
      axios
        .post("/pc/shipping/confirmInfo", {
          list: this.infoUpload.filter((v) => v.checked),
          company_id: this.page.company_id,
        })
        .then((res) => {
          this.showResult = false;
          this.init(this.page);
        });
    },
    handleChecked(selection, row) {
      let checkedArr = selection.map((v) => v.index);
      this.infoUpload.forEach((v, i) => {
        if (checkedArr.length) {
          this.$set(v, "checked", checkedArr.includes(i));
        } else {
          this.$set(v, "checked", false);
        }
      });
    },
    handleCheckedAll(selection) {
      let checkedArr = selection.map((v) => v.index);
      this.infoUpload.forEach((v, i) => {
        if (checkedArr.length) {
          this.$set(v, "checked", checkedArr.includes(i));
        } else {
          this.$set(v, "checked", false);
        }
      });
    },
    getTotalPrices() {
      api
        .get(
          `/v1/pc/receipt-ship/totalPrices?companyId=${String(
            local.get("company_id")
          )}`,
          {
            type: 0,
            endTime: this.end_time,
            startTime: this.add_time,
            shipping_sn: this.page.shipping_sn,
            goods_name: this.page.goods_name,
            consignee_company_name: this.page.consignee_company_name,
            gtin: this.page.gtin,
          }
        )
        .then((res) => {
          this.totalPrices = res.data;
        });
    },
    add() {
      this.$router.push({ name: "ShippingAdd", query: { type: 0 } });
    },
    beforeUpload(file) {
      // console.log(file,'文件');
      this.files = file;
      const extension2 = file.name.split(".")[1] === "xlsx";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension2) {
        this.$message.warning("上传模板只能是 xlsx格式!");
        return;
      }
      if (!isLt10M) {
        this.$message.warning("上传模板大小不能超过 10MB!");
        return;
      }
      this.fileName = file.name;
      setTimeout(() => {
        this.submitUpload();
      }, 500);
      return false; // 返回false不会自动上传
    },
    success() {
      this.$message.success("上传成功");
    },
    check(d_name) {
      axios.get("/pc/shipping/check", { down_name: d_name }).then((v) => {});
    },
    showImage(item) {
      this.reportList = [];
      this.picDialogVisible = true;
      let temp = JSON.parse(item.qualification);
      for (var key in temp) {
        temp[key].map((m) => {
          this.reportList.push(m.picUrl);
        });
      }
    },
    showImage1(item) {
      this.attachList = [];
      this.picDialogVisible1 = true;
      let temp = JSON.parse(item.real_pics);
      temp.map((m) => {
        this.attachList.push(m.url);
      });
    },
    // 上传excel
    submitUpload() {
      //console.log('上传'+this.files.name)
      if (this.fileName == "") {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();
      fileFormData.append("code", "t_pathology_info_excel");
      fileFormData.append("description", "发货信息上传");
      fileFormData.append("file", this.files, this.fileName);
      //filename是键，file是值，就是要传的文件，test是要传的文件名
      fileFormData.append("company_id", this.page.company_id);
      fileFormData.append("user_id", this.page.user_id);
      // 执行上传excel
      let id = "";
      axios
        .uploadFile("/pc/shipping/importExcel", fileFormData)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("excel上传失败，请重新上传");
          }
          if (
            resp.data.goodsNotExist.length > 0 ||
            resp.data.supplyNotExist.length > 0 ||
            resp.data.info.length > 0
          ) {
            this.showResult = true;
            this.infoUpload = resp.data.info.map((v, i) => ({
              ...v,
              index: i,
              checked: false,
            }));
            this.goodsExist = resp.data.goodsNotExist;
            this.supplyNotExist = resp.data.supplyNotExist;
             this.$nextTick(function () {
              this.$refs.infoTable.toggleAllSelection();
            });
          } else {
          }
          this.init(this.page);
        })
        .catch((e) => {
          //console.debug(e);
          this.$message.error("excel上传失败，请重新上传");
        })
        .finally(() => {
          if (id) {
            // 触发生成订单实体数据
            this.generateBill(id);
          }
        });
    },
    handlePictureCardPreview(url) {
      if (url) {
        this.dialogImageUrl = url;
        this.showViewer = true;
      } else {
        this.$message.warning("没有上传证件！");
      }
    },
    init(params) {
      axios.get("/pc/shipping/list", params).then((v) => {
        if (v.data.shipping_list.length) {
          this.tableData = v.data.shipping_list.map((item) => {
            if (item.ship_time) {
              let date = new Date(item.ship_time)
              // item.ship_time = item.ship_time.slice(0, 19).replace("T", " ");
              item.ship_time = date.toLocaleString('zh-CN', { hour12: false });
              // console.log(date.toLocaleString('zh-CN', { hour12: false }),item.ship_time);
            }
            return item;
          });
        } else {
          this.tableData = v.data.receipt_list;
        }

        this.getTotalPrices();
        this.total = v.data.pager_count;
      });
    },
    downloadInfo(param) {
      axios
        .fileget("/pc/download/template", { fileName: "shipping.xlsx" })
        .then((res) => {});
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init(this.page);
    },
    //批量导入
    batchAdd() {
      this.uploadVis = true;
    },
    //编辑
    goUpdate(row) {
      this.$router.push({
        name: "WarehouseListAdd",
        query: { warehouse_id: row.id, nature: row.nature },
      });
    },
    change(index, val) {
      var that = this;
      if (val == null) val = "";
      if (index == 1) {
        that.add_time = val;
      }
      if (index == 2) {
        that.end_time = val;
      }
      this.onSearch();
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios.put("", { car_id: id + "", car_state }).then((response) => {
        this.init(this.page);
      });
    },
    //导出数据
    exportData() {
      const secounds = 5;
      let num = 0;
      this.timer = setInterval(() => {
        if (num < secounds) {
          num++;
          this.check("shipping");
        } else {
          clearInterval(this.timer);
          this.loading = false;
        }
      }, 2000);
      let statename;
      //console.debug(this.value);
      statename =
        this.companyName +
        this.add_time +
        (this.end_time ? "至" : "") +
        this.end_time +
        "的发货记录信息";
      let data = {
        fileName: statename,
        companyId: this.page.company_id,
        addTime: this.add_time,
        endTime: this.end_time,
      };
      // order/companyName/goodsName/gtin
      if (this.page.consignee_company_name) {
        data.companyName = this.page.consignee_company_name;
      }
      if (this.page.shipping_sn) {
        data.order = this.page.shipping_sn;
      }
      if (this.page.goods_name) {
        data.goodsName = this.page.goods_name;
      }
      if (this.page.gtin) {
        data.gtin = this.page.gtin;
      }
      axios.fileget("/pc/shipping/exportExcel", data).then((v) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}

.demo-table-expand {
  font-size: 0;

  /deep/ .el-form-item__label {
    width: 90px;
    color: #99a9bf;
  }

  /deep/ .el-form-item__content {
    width: 50%;
  }

  /deep/ .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 10%;
  }
}

.add-btn {
  flex: 0 0 200px;
}

.result-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.result-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
/deep/ .el-dialog {
  border-radius: 10px;
}
</style>
